import { Box, StrokeButton } from '@codecademy/gamut';
import styled from '@emotion/styled';
import cx from 'classnames';
import React from 'react';

import { ccdata } from '~/libs/ccdata';
import { trackUserClick } from '~/libs/eventTracking';
import { addParamsAsHash } from '~/libs/url';
import {
  appleAuthenticationUrl,
  facebookAuthenticationUrl,
  githubAuthenticationUrl,
  googleAuthenticationUrl,
  linkedinAuthenticationUrl,
  twitterAuthenticationUrl,
} from '~/libs/urlHelpers';
import loginStrings from '~/localized/login.json';

import { TrackingData } from '../types';
import { SocialLogo } from './logos';
// eslint-disable-next-line gamut/no-css-standalone
import styles from './styles/index.module.scss';

interface OauthConfig {
  [serviceId: string]: {
    org: string; // Provider name
    url: string; // URL corresponding to endpoint that will handle SSO
    signInOnly: boolean; // Whether 'sign up' should be allowed (false if it should)
  };
}

const orgConfigs: OauthConfig = {
  apple: {
    org: 'Apple',
    url: appleAuthenticationUrl(),
    signInOnly: false,
  },
  facebook: {
    org: 'Facebook',
    url: facebookAuthenticationUrl(),
    signInOnly: false,
  },
  github: {
    org: 'Github',
    url: githubAuthenticationUrl(),
    signInOnly: false,
  },
  google: {
    org: 'Google',
    url: googleAuthenticationUrl(),
    signInOnly: false,
  },
  linkedin: {
    org: 'LinkedIn',
    url: linkedinAuthenticationUrl(),
    signInOnly: false,
  },
  twitter: {
    org: 'Twitter',
    url: twitterAuthenticationUrl(),
    signInOnly: true,
  },
};

type OrgKey = keyof OauthConfig;

const defaultOrder: OrgKey[] = [
  'google',
  'facebook',
  'linkedin',
  'github',
  'twitter',
  'apple',
];

const OauthButton = styled(StrokeButton)`
  border-width: 1px;
  border-radius: 2px;
  margin-bottom: 0.5rem;
  width: 100%;
  height: 48px;
  background-color: white;
  padding: 0.25rem;
`;

const ButtonForm = styled('form')`
  width: 100%;
`;

export interface OauthButtonGroupProps {
  signUp?: boolean;
  urlParams?: {};
  order?: OrgKey[];
  titlePrefix?: string;
  classNames?: {
    heading?: string;
    buttons?: string;
    button?: string;
    buttonTitle?: string;
    buttonContainer?: string;
  };
  trackingData?: TrackingData;
  userContext?: { mobile: string; whatsappOptIn?: boolean };
}

export const OauthButtonGroup: React.FC<
  React.PropsWithChildren<OauthButtonGroupProps>
> = ({
  classNames = {},
  order,
  signUp,
  titlePrefix,
  trackingData,
  urlParams,
  userContext,
}) => {
  const urlParamsWithTrackingData = trackingData
    ? {
        ...urlParams,
        ...userContext,
        page_name: trackingData.page_name,
        context: trackingData.context,
      }
    : { ...urlParams, ...userContext };

  return (
    <div data-testid="oauth-group">
      <h2 className={cx(styles.heading, classNames.heading)}>
        {signUp ? loginStrings.oauthRegister : loginStrings.oauthLogin}
      </h2>
      <Box as="ul" className={cx(styles.btnContainer, classNames.buttons)}>
        {(order || defaultOrder).map((org) => {
          const orgConfig = orgConfigs[org];
          const crsfToken = ccdata.get('authenticity_token');
          if (orgConfig.signInOnly && signUp) return null;
          return (
            <Box
              as="li"
              key={orgConfig.url}
              className={cx(
                styles.btnContainerItem,
                classNames.buttonContainer
              )}
            >
              <ButtonForm
                method="POST"
                action={addParamsAsHash(
                  orgConfig.url,
                  urlParamsWithTrackingData
                )}
              >
                <input
                  type="hidden"
                  name="authenticity_token"
                  value={crsfToken}
                />
                <OauthButton
                  aria-label={`${signUp ? 'Sign up' : 'Log in'}  with ${
                    orgConfig.org
                  }`}
                  variant="secondary"
                  type="submit"
                  onClick={() => {
                    const defaultTracking = {
                      context: `oauth_${orgConfig.org}_${
                        signUp ? 'register' : 'login'
                      }`,
                      target: 'oauth_button',
                    };
                    trackUserClick({
                      ...trackingData,
                      ...defaultTracking,
                    });
                  }}
                >
                  <SocialLogo org={orgConfig.org} />
                  {titlePrefix && (
                    <span className={cx(classNames.buttonTitle)}>
                      {`${titlePrefix} ${orgConfig.org}`}
                    </span>
                  )}
                </OauthButton>
              </ButtonForm>
            </Box>
          );
        })}
      </Box>
    </div>
  );
};
